"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class CpstestCtrl {
    constructor($scope, $timeout, $filter, ConfigService, SoundService) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$filter = $filter;
        this.ConfigService = ConfigService;
        this.SoundService = SoundService;
        this.timers = [1, 3, 5, 10, 15, 30, 60, 100, 300, 600];
        this.timer = JSON.parse(localStorage.getItem(`${this.constructor.name}_timer`) || '{"value": 10}').value;
        this.stopwatch = this.timer;
        this.cps = 0;
        this.avgCps = 0;
        this.total = 0;
        this.game$ = new rxjs_1.Subject();
        this.stop$ = new rxjs_1.Subject();
        this.timerState = 'stop';
    }
    $onInit() {
        this.stop$.pipe((0, operators_1.tap)(() => {
            this.timerState = 'stop';
            this.stopwatch = this.timer;
            this.total = 0;
            this.avgCps = 0.00;
            this.cps = 0;
        })).subscribe();
    }
    $postLink() {
        const cpstest = document.getElementById('cps-test');
        this.game$.pipe((0, operators_1.filter)(() => {
            return this.timerState != 'progress';
        }), (0, operators_1.switchMap)(() => {
            this.timerState = 'progress';
            const startTs = Date.now();
            let count = 0;
            return (0, rxjs_1.merge)((0, rxjs_1.interval)(10).pipe((0, operators_1.tap)(() => {
                const sec = (Date.now() - startTs) / 1000;
                this.$scope.$apply(() => {
                    this.stopwatch = this.timer - sec;
                    this.avgCps = count / sec;
                });
            }), (0, operators_1.takeUntil)((0, rxjs_1.race)((0, rxjs_1.timer)(990).pipe((0, operators_1.tap)(() => {
                this.$scope.$apply(() => {
                    this.cps = this.total;
                    this.avgCps = this.total;
                });
            })), this.stop$))), (0, rxjs_1.fromEvent)(cpstest, 'click')).pipe((0, operators_1.filter)((e) => {
                return e instanceof PointerEvent;
            }), (0, operators_1.tap)(() => {
                count += 1;
                this.$scope.$apply(() => {
                    this.total = count;
                });
            }), (0, operators_1.bufferTime)(1000, 10), (0, operators_1.tap)((a) => {
                const sec = (Date.now() - startTs) / 1000;
                this.$scope.$apply(() => {
                    this.cps = a.length;
                    this.stopwatch = this.timer - sec;
                    this.avgCps = count / sec;
                });
            }), (0, operators_1.takeUntil)((0, rxjs_1.race)((0, rxjs_1.timer)(this.timer * 1000).pipe((0, operators_1.tap)(() => {
                this.$timeout(() => {
                    this.timerState = 'result';
                    this.stopwatch = 0.00;
                });
            })), this.stop$)));
        })).subscribe();
    }
    setTimer(value) {
        localStorage.setItem(`${this.constructor.name}_timer`, JSON.stringify({ value: value }));
        // if (this.timerState == 'result') {
        //     this.timerState = 'stop'
        //     this.stopwatch = this.timer
        //     this.total = 0
        //     this.avgCps = 0.00
        //     this.cps = 0
        // }
        this.timer = value;
        this.stop$.next();
    }
    gameStart() {
        if (this.timerState == 'stop') {
            this.stopwatch = this.timer;
            this.total = 0;
            this.avgCps = 0.00;
            this.cps = 0;
            this.game$.next();
        }
    }
    gameContinue(e) {
        e.stopPropagation();
        this.timerState = 'stop';
        this.gameStart();
    }
    getTitle() {
        if (this.avgCps != undefined) {
            if ((this.avgCps >= 0) && (this.avgCps < 0.99)) {
                return this.$filter('translate')('Snail');
            }
            else if ((this.avgCps >= 1) && (this.avgCps < 2.99)) {
                return this.$filter('translate')('Koala');
            }
            else if ((this.avgCps >= 3) && (this.avgCps < 4.99)) {
                return this.$filter('translate')('Turtle');
            }
            else if ((this.avgCps >= 5) && (this.avgCps < 6.99)) {
                return this.$filter('translate')('Panda');
            }
            else if ((this.avgCps >= 7) && (this.avgCps < 8.99)) {
                return this.$filter('translate')('Hare');
            }
            else if ((this.avgCps >= 9) && (this.avgCps < 9.99)) {
                return this.$filter('translate')('Kangaroo');
            }
            else if ((this.avgCps >= 10) && (this.avgCps < 10.99)) {
                return this.$filter('translate')('Panther');
            }
            else if ((this.avgCps >= 11) && (this.avgCps < 11.99)) {
                return this.$filter('translate')('Cheetah');
            }
            else if ((this.avgCps >= 12) && (this.avgCps < 100)) {
                return this.$filter('translate')('Superhuman');
            }
        }
    }
}
CpstestCtrl.$inject = ['$scope', '$timeout', '$filter', 'ConfigService', 'SoundService'];
const appModule = ng.module('app');
appModule.directive('gameMaterialClick', [() => {
        return {
            link: ($scope, $element) => {
                $element.on('click', function (e) {
                    var circle, d, x, y;
                    const element = $element[0];
                    circle = element.querySelector('.md-click-circle');
                    if (!circle) {
                        circle = document.createElement('span');
                        circle.className = 'md-click-circle';
                        element.prepend(circle);
                    }
                    circle.classList.remove("md-click-animate");
                    if (!circle.style.height && !circle.style.width) {
                        d = Math.max(window.outerWidth, window.outerHeight);
                        circle.style.height = `${d}px`;
                        circle.style.width = `${d}px`;
                    }
                    x = e.pageX - element.offsetLeft - circle.clientWidth / 2;
                    y = e.pageY - element.offsetTop - circle.clientWidth / 2;
                    circle.style.top = `${y}px`;
                    circle.style.left = `${x}px`;
                    circle.classList.add('md-click-animate');
                });
            }
        };
    }]);
appModule.component('gameCpstest', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: CpstestCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('cpstest/');
    }]);
